 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import React, {lazy} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router'
import useAuthRoute from '../hooks/useAuthRoute'
import useNoIndex from '../hooks/useNoIndex'

const NewMeetingSummary = lazy(
  () =>
    import(
      /* webpackChunkName: 'NewMeetingSummaryRoot' */ '../modules/summary/components/NewMeetingSummaryRoot'
    )
)
const Graphql = lazy(
  () =>
    import(
      /* webpackChunkName: 'GraphqlContainer' */ '../modules/admin/containers/Graphql/GraphqlContainer'
    )
)
const Impersonate = lazy(
  () =>
    import(
      /* webpackChunkName: 'ImpersonateContainer' */ '../modules/admin/containers/Impersonate/ImpersonateContainer'
    )
)
const Signout = lazy(
  () => import(/* webpackChunkName: 'SignoutContainer' */ '../containers/Signout/SignoutContainer')
)
const NotFound = lazy(() => import(/* webpackChunkName: 'NotFound' */ './NotFound/NotFound'))
const DashboardRoot = lazy(() => import(/* webpackChunkName: 'DashboardRoot' */ './DashboardRoot'))
const MeetingRoot = lazy(() => import(/* webpackChunkName: 'MeetingRoot' */ './MeetingRoot'))
const MeetingSeriesRoot = lazy(
  () => import(/* webpackChunkName: 'MeetingSeriesRoot' */ './MeetingSeriesRoot')
)
const ViewerNotOnTeamRoot = lazy(
  () => import(/* webpackChunkName: 'ViewerNotOnTeamRoot' */ './ViewerNotOnTeamRoot')
)

const ActivityLibraryRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: 'ActivityLibraryRoutes' */ './ActivityLibrary/ActivityLibraryRoutes'
    )
)

const ReviewRequestToJoinOrgRoot = lazy(
  () => import(/* webpackChunkName: 'ReviewRequestToJoinOrgRoot' */ './ReviewRequestToJoinOrgRoot')
)

const ShareTopicRouterRoot = lazy(
  () => import(/* webpackChunkName: 'ShareTopicRouterRoot' */ './ShareTopicRouterRoot')
)

const PrivateRoutes = () => {
  useAuthRoute()
  useNoIndex()
  const location = useLocation()
  const state = location.state
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Switch, { location: _optionalChain([state, 'optionalAccess', _ => _.backgroundLocation]) || location,}
        , React.createElement(Route, { path: "/activity-library", component: ActivityLibraryRoutes,} )
        , React.createElement(Route, {
          path: "(/meetings|/me|/newteam|/team|/usage|/organization-join-request)",
          component: DashboardRoot,}
        )
        , React.createElement(Route, { path: "/new-meeting",}
          , React.createElement(Redirect, { to: "/activity-library",} )
        )
        , React.createElement(Route, { path: "/meet/:meetingId", component: MeetingRoot,} )
        , React.createElement(Route, { path: "/meeting-series/:meetingId", component: MeetingSeriesRoot,} )
        , React.createElement(Route, { path: "/new-summary/:meetingId/:urlAction?", component: NewMeetingSummary,} )
        , React.createElement(Route, { path: "/admin/graphql", component: Graphql,} )
        , React.createElement(Route, { path: "/admin/impersonate", component: Impersonate,} )
        , React.createElement(Route, { path: "/invitation-required", component: ViewerNotOnTeamRoot,} )
        , React.createElement(Route, { path: "/signout", component: Signout,} )
        , React.createElement(Route, { component: NotFound,} )
      )
      , React.createElement(Switch, null
        , React.createElement(Route, {
          path: "/organization-join-request/:requestId",
          component: ReviewRequestToJoinOrgRoot,}
        )
        , React.createElement(Route, { path: "/new-summary/:meetingId/share/:stageId", component: ShareTopicRouterRoot,} )
      )
    )
  )
}

export default PrivateRoutes
